import { render, staticRenderFns } from "./TripType.vue?vue&type=template&id=5a3a07f2&scoped=true"
import script from "./TripType.vue?vue&type=script&lang=js"
export * from "./TripType.vue?vue&type=script&lang=js"
import style0 from "./TripType.vue?vue&type=style&index=0&id=5a3a07f2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a3a07f2",
  null
  
)

export default component.exports