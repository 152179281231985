
import { TRIP_TYPES } from '~/common/constants';

export default {
  name: 'TripType',
  props: {
    value: {
      type: String,
      required: true,
      default: TRIP_TYPES.ROUND_TRIP,
    },
  },
  computed: {
    tripTypes () {
      return [
        {
          value: TRIP_TYPES.ONE_WAY,
          text: this.$t('tripType.oneWay')
        },
        {
          value: TRIP_TYPES.ROUND_TRIP,
          text: this.$t('tripType.roundTrip')
        },
      ];
    },
    currentValue () {
      return (
        this.tripTypes.find(({ value }) => this.value === value)?.text || ''
      );
    },
  },
  methods: {
    changeTripType ({ value }) {
      this.$emit('input', value);
    },
  },
};
